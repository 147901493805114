.App {
  text-align: center;
  padding: 0 10px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #fff;
  background-size: 100px 100px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.nav-header {
  display: flex;
  text-align: left;
  position: relative;
  z-index: 21474836479999;
}
.logo {
  width: 110px;
  height: 50px;
  padding: 30px 0 0 30px;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.MuiTypography-body1,
.MuiTypography-h6 {
  font-family: Roboto, sans-serif !important;
}

body {
  font-family: Roboto, sans-serif !important;
}

@media (max-width: 512px) {
  .showOnMobile {
    display: block;
  }
  .showOnWeb {
    display: none;
  }
}

@media (min-width: 512px) {
  .showOnMobile {
    display: none;
  }
  .showOnWeb {
    display: block;
  }
}

.showOnMobile > a > button {
  color: #5a2aea;
  box-shadow: none;
  background-color: transparent;
}

a {
  text-decoration: none !important;
}

.geetest_captcha .geetest_holder,
.geetest_popup_wrap .geetest_holder {
  margin: auto;
}

.MuiButton-root {
  border-radius: 8px;
  padding: 16px;
  margin: 16px;
}
